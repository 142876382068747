<template>
  <div :dir="lang == 'he' ? 'rtl' : 'ltr'" class="area">
    <div class="maintenance" v-if="isMaintenance">
      <img src="assets/img/maintenance.jpg" />
      <!-- <p >{{ $t('home.maintenance') }}</p> -->
    </div>
    <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark"  v-if="isAgency">
      <!-- <template #header>{{ $t('home.no-registering-title') }}</template> -->
      <template #lead >
        <p>{{ $t('home.no-registering-agency') }}</p>
      </template>

      <hr class="my-4" />

      <b-button variant="primary" href="#" @click="gotoMainDomain">{{
        $t('home.go-main-domain')
      }}</b-button>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BJumbotron, BButton } from 'bootstrap-vue';

export default {
  components: {
    BJumbotron, BButton,
  },
  data() {
    return {
      isAgency: false,
      isMaintenance: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
  },
  methods: {
    gotoMainDomain() {
      // window.location.href = process.env.VUE_APP_MAIN_DOMAIN;
      window.location.href = 'https://flying-carpet.vercel.app/';
    },
  },
};

</script>

<style scoped>
  .area {
    display: flex;
    height: 100vh;
  }
  .maintenance {
    width: 70%;
    overflow: hidden;
    display: flex;
    margin:auto;
  }

  .maintenance > img{
    width: 100%;
    margin: auto;
  }

  @media (max-width: 476px) {
    .maintenance {
      width: 95%;
    }
  }
</style>
